<template>
  <div class="overview-lookalikes">
    <div class="text-h6 font-weight-bold mb-4">
      Influencers Lookalikes
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Top relevant influencers writing on similar topics.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between">
      <v-card-text class="flex-grow-1">
        <v-list dense two-line>
          <v-list-item
            v-for="item in similarUsers.slice(0, 3)"
            :key="item.user_id"
            @click="redirectToWeb(item.url)"
          >
            <v-list-item-avatar>
              <img :src="proxyUrl(item.picture)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.fullname }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ nFormatter(item.followers) }} followers,
                {{ nFormatter(item.engagements) }} engagements
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          Influencer Lookalikes
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-list dense two-line>
            <v-list-item
              v-for="item in similarUsers"
              :key="'item-' + item.user_id"
              @click="redirectToWeb(item.url)"
            >
              <v-list-item-avatar>
                <img :src="proxyUrl(item.picture)" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fullname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nFormatter(item.followers) }} followers,
                  {{ nFormatter(item.engagements) }} engagements
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "InfluencerLookalikes",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  // Local data variables
  data: () => ({
    // Whether or not to show the dialog for list
    shouldShowDialog: false
  }),

  // Computed data variables
  computed: {
    /**
     * Get the list of users from incoming data
     *
     * @returns {Array}
     */
    similarUsers() {
      return this.userData.data.user_profile.similar_users
    }
  }
}
</script>
